import React from "react-dom/client";
import { useState } from 'react';
import { Get } from '../../hooks/useFetch';
import PropTypes from 'prop-types';
import Footer from '../../pages/footer';

export default function Login({ setToken }) {
  const [username, setUserName] = useState();
  const [password, setPassword] = useState();

  const handleSubmit = async e => {
    e.preventDefault();

    var errorElement = document.getElementById("loginError");
    errorElement.innerHTML = '';

    const encodedUsername = encodeURIComponent(username);
    const encodedPassword = encodeURIComponent(password);
    const url = `/Authentication?username=${encodedUsername}&password=${encodedPassword}`;

    Get(url, (response) => {
      setToken(response);
    }, (text, error) => {
      if (error === 401) {
        errorElement.innerHTML = 'Incorrect username or password';
      } else {
        errorElement.innerHTML = text;
      }
    })
  }

  return <>
    <div className="page-wrapper page-center">
      <div className="container container-tight py-4">
        <div className="card card-md">
          <div className="card-body">
            <div className="text-center mb-4 d-print-none text-white">
              <a href="." className="navbar-brand navbar-brand-autodark">
                <img src="/images/logo-dark.png" height="46" alt="Pillars" />
              </a>
            </div>
            <h2 className="h2 text-center mb-4">Login to Pillars Portal</h2>
            <form onSubmit={handleSubmit} autoComplete="off" noValidate>
              <div className="mb-3">
                <label className="form-label">Email address</label>
                <input type="email" className="form-control" placeholder="Email" autoComplete="off" onChange={e => setUserName(e.target.value)} />
              </div>
              <div className="mb-2">
                <label className="form-label">
                  Password
                </label>
                <input type="password" className="form-control" placeholder="Password" autoComplete="off" onChange={e => setPassword(e.target.value)} />
              </div>
              <div className="mb-2">
                <label className="form-check">
                  <span className="form-label-description">
                    <a href="/account/forgotPassword">Forgot password</a>
                  </span>
                </label>
              </div>
              <span id="loginError" className="text-danger"></span>
              <div className="form-footer">
                <button type="submit" className="btn btn-primary w-100">Sign in</button>
              </div>
            </form>
            <div className="text-center text-muted mt-3">
              Don&apos;t have account yet? <a href="/register">Sign up</a>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Footer />
  </>
}

Login.propTypes = {
  setToken: PropTypes.func.isRequired
}
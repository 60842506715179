import React from 'react';
import PropTypes from 'prop-types';
import TextInput from '../../../components/textInput';

const ComponentSelect = ({ name, componentId, parameters, onChange }) => {
  let components = [
    { id: "SOURCE", title: "Source", description: "Defines a source volume", tValue: ["SourceVolume", "IsFastStart"] },
    { id: "FixedVolume", title: "Fixed Volume", description: "Volume that is always a specific amount for a sepcified customer type", tValue: ["RequiredSourceVolume", "RequiredVolume", "RequiredExact", "Volume", "CustomerTypes"] },
    { id: "CustomerVolume", title: "Customer Volume", description: "Volume from retail customers", tValue: ["SourceVolumeKey", "CustomerTypeKey", "Personal", "WholesaleTypes", "RetailTypes"] },
    { id: "PersonalVolume", title: "Personal Volume", description: "Volume for customer of a sepcified type", tValue: ["SourceVolumeKey", "CustomerTypeKey", "CustomerTypes"] },
    { id: "GVTask", title: "Group Volume", description: "Group Downline volume", tValue: ["SourceVolume", "Tree"] },
    { id: "HasVolume", title: "Has Volume", description: "Checks to see if a volume amount has been meet", tValue: ["SourceVolume", "Volume", "Exact"] },
    { id: "HadVolume", title: "Had Volume", description: "Checks to see if a volume amount has been meet. Once true, the value will persist until false requirements are met", tValue: ["TrueSourceVolume", "TrueVolume", "TrueExact", "FalseSourceVolume", "FalseVolume", "FalseExact"] },
    { id: "LegVolume", title: "Leg Volume", description: "Volume from a specific leg in a tree", tValue: ["Leg", "SourceVolume", "Levels", "Tree"] },
    { id: "HighLegVolume", title: "High Leg Volume", description: "Volume from a specific leg index, srarting with the highest leg to the lowest leg, in a tree", tValue: ["SourceVolume", "HighIndexes", "Exclusive" , "Tree"] },
    { id: "JoinVolume", title: "Join Volume", description: "Combines two volumes together", tValue: ["RequiredSourceVolume", "RequiredVolume", "RequiredExact", "SourceVolume"] },
    { id: "SubtractVolume", title: "Subtract Volume", description: "Subtracts a volume from anther volume", tValue: ["SourceVolume", "SubtractVolume"] },
    { id: "LegRollover", title: "Rollover Volume", description: "Rolls forward remaining volume after subtracting used volume", tValue: ["SourceVolume", "SubtractVolume"] },
    { id: "InLegCount", title: "In Leg Count", description: "Count the number of personally enrolled nodes been the required volume", tValue: ["Tree", "SponsorTree", "Leg", "SourceVolume", "Volume", "Exact"] },
    { id: "AndVolume", title: "And Volume", description: "Volume will be 1 if all the volume meets the required amount", tValue: ["SourceVolumes", "Volumes"] },
    { id: "OrVolume", title: "Or Volume", description: "Volume will be 1 if any of the volume meets the required amount", tValue: ["SourceVolumes", "Volumes"] },
    { id: "LegCount", title: "Leg Count", description: "The number of legs that have the required volume in that leg", tValue: ["SourceVolume", "Volume", "Exact", "Levels", "Tree"] },
    { id: "RollForward", title: "Roll Forward", description: "Rolls volume forward for a specific number of periods", tValue: ["RequiredSourceVolume", "RequiredVolume", "RequiredExact", "SourceVolume", "Periods"] },
    { id: "FirstVolume", title: "First Volume", description: "Volume for the first accurance of source volume", tValue:  ["SourceVolume"] },
    { id: "SplitVolume", title: "Split Volume", description: "Volume that is capped or limited to a speific value, based on minimun and maximum values", tValue: ["SourceVolume", "Minimum", "Maximum"] },
    { id: "DayRange", title: "Day Range Volume", description: "Volume that is generated within the Begin to End day of the period", tValue: ["SourceVolume", "BeginDay", "EndDay"] },
    { id: "IntersectVolume", title: "Intersect Volume", description: "Only volume that are from both source volumes will be used", tValue: ["SourceVolumes"] },
    { id: "PercentVolume", title: "Percent Volume", description: "Takes a percent of a volume", tValue: ["SourceVolume", "Percent"] },
    { id: "EnrolledIn", title: "Enrolled In Period", description: "Volume will be 1 if Enroll Date is in the periods defined", tValue: ["PeriodIndex"] },
  ];

  let tValue = components.find(x => x.id == componentId)?.tValue;

  const handleComponentIdChange = (value) => {
    onChange(value, []);
  }

  const handleParameterChange = ( name, value ) => {
    var index = parameters.findIndex((e) => e.id === name);
    if (index > -1) {
      parameters[index].value = value;
    } else{
      parameters.push({id: name, value: value});
    }

    onChange(componentId, parameters);
  }

  return <>
    <div className="mb-3">
      <label className="form-label">Component</label>
      <select className="form-control" name={name} value={componentId ?? ''} onChange={(e) => { handleComponentIdChange(e.target.value) }}>
        <option value="" disabled >-- select option --</option>
        {components && components.map((component) =>{
          return <option key={component.id} value={component.id}>{component.title} - {component.description}</option>
        })}
      </select>
    </div>
    <div className="row">
      {tValue && tValue.map((input) => {
        let value = parameters?.find((e) => e.id == input)?.value ?? '';
        return <div key={input} className="col">
          <label className="form-label">{input}</label>
          <TextInput name={input} value={value} onChange={handleParameterChange}  />
        </div>
      })}
    </div>
  </>
};

export default ComponentSelect;

ComponentSelect.propTypes = {
  name: PropTypes.string.isRequired,
  componentId: PropTypes.string.isRequired,
  parameters: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired
}
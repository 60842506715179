import React, { useEffect, useState } from 'react';
import { useFetch } from '../../hooks/useFetch'
import DailyCountChart from './charts/dailyCountChart';
import ResponseTimeChart from './charts/responseTimeChart';
import AddEnvironmentModal from './pageComponents/addEnvironmentModal';

const Home = () => {
  const today = new Date();
  const beginningOfMonth = new Date(today.getFullYear(), today.getMonth(), 1);
  const tenDaysAgo = new Date();
  tenDaysAgo.setDate(tenDaysAgo.getDate() - 11);
  const startDate = beginningOfMonth > tenDaysAgo ? beginningOfMonth : tenDaysAgo;

  const [stats, setStats] = useState({ total: 0 });
  const { loading, error, data, refetch } = useFetch("/api/v1/Environments", {});
  const { error: statsError, data: statsData } = useFetch("/api/v1/Logs/stats", { date: startDate.toISOString() });

  useEffect(() => {
    if (statsData) {
      var count = statsData.reduce((acc, s) => acc + s.callCount, 0);
      setStats({ total: count, percent: 15 });
    }
  }, [statsData])

  if (loading) return <span>Loading...</span>;
  if (error) return `Error! ${error}`;
  if (statsError) return `Error! ${statsError}`;

  const groupedArray = statsData?.filter(item => new Date(item.date) >= beginningOfMonth).reduce((acc, currentItem) => {
    const existingItem = acc.find(item => item.environmentId === currentItem.environmentId);

    if (existingItem) {
      // If the date already exists, update the sum and count
      existingItem.sumResponseTime += currentItem.averageResponseTime;
      existingItem.count += 1;
      existingItem.callCount += currentItem.callCount;
    } else {
      // If the date doesn't exist, add a new entry to the accumulator
      acc.push({
        sumResponseTime: currentItem.averageResponseTime,
        count: 1,
        callCount: currentItem.callCount,
        environmentId: currentItem.environmentId,
      });
    }

    return acc;
  }, []) ?? [];

  const averageResponseTimes = groupedArray.map(item => ({
    averageResponseTime: Math.round(item.sumResponseTime / item.count),
    environmentId: item.environmentId,
    callCount: item.callCount
  }));

  return <>
    <div className="page-header d-print-none">
      <div className="container-xl">
        <div className="row align-items-center">
          <div className="col">
            <div className="page-pretitle">
              OVERVIEW
            </div>
            <h2 className="page-title">
              Environments
            </h2>
          </div>
          <div className="col-auto ms-auto d-print-none">
            <div className="btn-list">
              <AddEnvironmentModal refetch={refetch} />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div className="page-body">
      <div className="container-xl">
        <div className="row row-deck row-cards">
          <div className="col-12">
            <div className="card">
              <table className="table card-table table-vcenter table-responsive">
                <thead>
                  <tr>
                    <th>Name</th>
                    <th>AVERAGE RESPONSE TIME</th>
                    <th>REQUESTS THIS PERIOD</th>
                    <th>STATUS</th>
                    <th className="w-1"></th>
                  </tr>
                </thead>
                <tbody>
                  {data && data.map((env) => {
                    const stat = averageResponseTimes.find(x => x.environmentId == env.id);
                    return <tr key={env.id}>
                      <td>{env.name}</td>
                      <td>{stat?.averageResponseTime ?? 0} ms </td>
                      <td>{stat?.callCount ?? 0}</td>
                      <td>
                        {env.status == 0 ?
                          <><span className="badge bg-success me-1"></span> Ready </> :
                          <><span className="badge bg-warning me-1"></span> Initialized </>
                        }
                      </td>
                      <td>
                        <div className="btn-list flex-nowrap">
                          <a href={`/environments/${env.id}`} className="btn btn-default w-100" >Configure</a>
                          {env.status == 0 && <a href="https://app.pillarshub.com" className="btn btn-default btn-icon" >
                            <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-login" width="24" height="24" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none" /><path d="M15 8v-2a2 2 0 0 0 -2 -2h-7a2 2 0 0 0 -2 2v12a2 2 0 0 0 2 2h7a2 2 0 0 0 2 -2v-2" /><path d="M21 12h-13l3 -3" /><path d="M11 15l-3 -3" /></svg>
                          </a>}
                        </div>
                      </td>
                    </tr>
                  })}
                </tbody>
              </table>
              {(!data || data.length == 0) && <>
                <div className="card-body">
                  <div className="empty">
                    <p className="empty-title">No Environments</p>
                    <p className="empty-subtitle text-muted">
                      To ensure proper system functionality, please configure at least one environment.
                    </p>
                    <div className="empty-action">
                      <AddEnvironmentModal refetch={refetch} />
                    </div>
                  </div>
                </div>
              </>}
            </div>
          </div>

          <div className="col-md-4">
            <div className="row row-deck row-cards" >



              <div className="card">
                <div className="card-body">
                  <div className="d-flex align-items-center">
                    <div className="subheader">Requests this period</div>
                    <div className="ms-auto lh-1">
                      {/* <span className="text-muted">of 5,000</span> */}
                    </div>
                  </div>
                  <div className="h1 mb-3">
                    {stats.total}
                  </div>
                  <div className="d-flex mb-2">
                    <div>Subscription Usage</div>
                  </div>
                  <div className="progress progress-sm">
                    <div className="progress-bar bg-green" style={{ width: `${stats.percent}%` }} role="progressbar" aria-valuenow="0" aria-valuemin="0" aria-valuemax="100">
                      <span className="visually-hidden">{stats.percent}% Complete</span>
                    </div>
                  </div>
                </div>
              </div>



              <div className="card">
                <div className="card-body">
                  <h3 className="card-title">Average Response Time</h3>
                  <ResponseTimeChart data={statsData} />
                </div>
              </div>
            </div>
          </div>

          <div className="col-md-8">
            <div className="card">
              <div className="card-body">
                <h3 className="card-title">Requests per day</h3>
                <DailyCountChart data={statsData} />
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>
  </>
};

export default Home;
